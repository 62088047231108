import { Component, Prop, Watch } from 'vue-property-decorator'
import GtrSuper from "@/modules/common/components/mixins/gtr-super.mixin";
import ErrorHandlerService from '@/modules/common/services/error-handler.service';
import Container from 'typedi';
import Notification from '@/modules/common/services/notification.service';
import GtrStorage from '@/modules/common/services/storage.service';
import { mapState } from 'vuex';

@Component({
    name: 'GtrLeadsLoginView',
    computed: {
        ...mapState('options', ['participantUpdated']),
        ...mapState('auth', ['needAuthField', 'requireInvite']),
        ...mapState('event', ['event'])
    }
})
export default class GtrLeadsLoginView extends GtrSuper {
    @Prop()
    allContent: any

    @Prop()
    design: any

    @Prop()
    settings: any

    @Prop()
    option_groups: any

    @Prop()
    tiers: any

    @Prop()
    content_pages: any

    @Prop()
    event_uuid: any

    @Prop()
    template_name: any

    @Prop()
    participant: any

    data() {
        return {
            option_group_uuid: false,
            registration_type: false,
            event_identifier: '',
            login_key: '',
            pagenum: '',
            loginFieldData: {},
            currentLanguage: 'en',
            requestInviteDialog: false,
            RI_first_name: '',
            RI_last_name: '',
            RI_email: '',
            _requireInvite: null,
            _event: null,
            referralCode: ''
        }
    }

    //#region Lifecycle
    async mounted() {
        this.$data.event_identifier = this.$route.params.event_identifier
        this.$data.pagenum = this.$route.params.pagenum
        this.$data.login_key = this.$route.params.login_key
        this.$data.referralCode = this.$route.query.referralCode
        const $_GET: any = {}
        if (document.location.toString().indexOf('?') !== -1) {
            const query = document.location.search.substring(1).split('&')

            for (let i = 0, l = query.length; i < l; i++) {
                const aux = decodeURIComponent(query[i]).split('=')
                $_GET[aux[0]] = aux[1]
            }
        }
        if ($_GET['language']) {
            this.$data.currentLanguage = $_GET['language']
        }
        const self = this
        setTimeout(function() {
            self.$store.dispatch('event/sendAnalytics', { event_identifier: self.$data.event_identifier })
        }, 1000)
        if (this.$data.referralCode) {
            this.addToReferralCodeClickCount()
        }
    }
    //#endregion

    //#region Watchers
    @Watch('requireInvite')
    onRequireInviteChange (payload: any) {
        if (payload) {
            this.$data._requireInvite = payload
        }
    }

    @Watch('event')
    onEventChange (payload: any) {
        if (payload) {
            this.$data._event = payload
        }
    }

    @Watch('participantUpdated')
    onParticipantUpdatedChange (payload: any) {
        if (payload) {
            this.$router.push('/' + (this.isDev ? 'dev/' : '') + this.$data.event_identifier + '/register/0')
        }
    }

    @Watch('settings')
    async onSettingsChange (payload: any) {
        if (this.$data.login_key && !payload.editing_disabled) {
            const payload = {
                event_identifier: this.$data.event_identifier,
                isDev: this.isDev,
                form: 'lr_orders',
                data: {
                    login_key: this.$data.login_key,
                    pagenum: this.$data.pagenum,
                }
            }
            try {
                await this.$store.dispatch('auth/login', payload)
            } catch (error) {
                Container.get(ErrorHandlerService).error(error)
            }
        }
    }
    //#endregion

    //#region Computed Props
    get loginFields() {
        return this.settings.login_fields
    }

    get subscription_level() {
        return this.$data._event ? this.$data._event.subscription_level : ''
    }

    get needRegType() {
        return this.settings.require_reg_type_before_registering && !this.$data.registration_type
    }

    get regTypeVerbiage() {
        return this.settings.regtype_selection_verbiage
    }

    get regTypeOptions() {
        for (const option_group_index in this.option_groups) {
            const option_group = this.option_groups[option_group_index]
            if (option_group.name == 'Registration Types') {
                return option_group.options
            }
        }
        return null
    }

    get optionGroupUuid() {
        for (const option_group_index in this.option_groups) {
            const option_group = this.option_groups[option_group_index]
            if (option_group.name == 'Registration Types') {
                return option_group.uuid
            }
        }
        return null
    }

    get showTheRequestInviteButton() {
        return this.$data._requireInvite && this.settings.allow_invite_requests
    }

    get isLoginClosed() {
        return this.settings.login_closed
    }

    get isEditingDisabled() {
        return this.settings.editing_disabled
    }

    get login_button_text() {
        return this.design.default_login_button_text && this.design.default_login_button_text[this.$data.currentLanguage]
            ? this.design.default_login_button_text[this.$data.currentLanguage]
            : 'Login'
    }
    //#endregion

    //#region Methods
    async addToReferralCodeClickCount () {
        try {
            const data = {
                event_identifier: this.$data.event_identifier,
                referral_code: this.$data.referralCode
            }
            await this.$store.dispatch('promote/addToReferralCodeClickCount', data)
        } catch (error) {
            Container.get(ErrorHandlerService).error(error)
        }
    }

    async setRegType(registration_type_param: any) {
        try {
            this.$data.registration_type = registration_type_param
            const payload: any = {
                event_identifier: this.event_uuid,
                reg_type: this.$data.registration_type.uuid,
            }
            await this.$store.dispatch('register/getAllContent', payload)
        } catch (error) {
            Container.get(ErrorHandlerService).error(error)
        }
    }

    async requestInvite() {
        try {
             this.$data.requestInviteDialog = false
             const payload: any = {
                 event_identifier: this.$data.event_identifier,
                 data: {
                     first_name: this.$data.RI_first_name,
                     last_name: this.$data.RI_last_name,
                     email: this.$data.RI_email,
                 }
             }
             await this.$store.dispatch('auth/requestInvite', payload)
        } catch (error) {
            Container.get(ErrorHandlerService).error(error)
        }
    }

    async login () {
        this.$store.dispatch('common/showLoader', { value: true})
        const payload: any = {
            event_identifier: this.$data.event_identifier,
            pagenum: this.$data.pagenum,
            isDev: this.isDev,
            form: 'lr_orders',
            data: {
                login_key: this.$data.login_key,
                referral_code: this.$data.referralCode // this will update referral code participant usage count
            }
        }
        for (const field in this.$data.loginFieldData) {
            payload.data[field] = this.$data.loginFieldData[field]
        }
        if (this.$data.registration_type && this.$data.registration_type.uuid) {
            payload.data.registrationTypeData = {
                option_group_uuid: this.optionGroupUuid,
                option_uuid: this.$data.registration_type.uuid,
            }
        }
        try {
            const response = await this.$store.dispatch('auth/login', payload)
            const highest_submitted_page_lr = response.data.participant_data.highest_submitted_page_lr
            this.$router.push('/' + (this.isDev ? 'dev/' : '') + payload.event_identifier + '/leads/order/' + (highest_submitted_page_lr ? highest_submitted_page_lr : 0))
        } catch (error) {
            if (error.response.data.error_code === 'AUTH_FIELD_MISMATCH') {
                Container.get(Notification).error(error.response.data.error_message)
                return
            }
            if (error.response.data.error_code === 'NEED_AUTH_FIELD') {
                this.$store.commit('auth/SET_NEED_AUTH_FIELD', error.response.data.field_needed)
            }
            if (error.response.data.error_code === 'REGISTRATION_NOT_FOUND') {
                if (payload.participant_uuid) {
                    this.$router.push('/' + (this.isDev ? 'dev/' : '') + this.$data.event_identifier)
                    Container.get(Notification).error('This registration id does not exist.')
                    return
                }
                try {
                    const response = await this.$store.dispatch('auth/registration', payload)
                    const access_token = response.data.access_token
                    /**
                     * Keep the group parent access token for group related calls
                     * Use the new access token created here for all other calls
                     */
                    if (Container.get(GtrStorage).getItem(`${this.$data.event_identifier}_registration_access_token`) && Container.get(GtrStorage).getItem(`${this.$data.event_identifier}_group_uuid`) && !Container.get(GtrStorage).getItem(`${this.$data.event_identifier}_group_parent_access_token`)) {
                        /**
                         * First registration's access token should now be the group parent token, to be used in group calls
                         */
                        Container.get(GtrStorage).setItem(`${this.$data.event_identifier}_group_parent_access_token`,Container.get(GtrStorage).getItem(`${this.$data.event_identifier}_registration_access_token`))
                    }
                    /**
                     * After saving the group parent access token or just doing a regular single registration login, set the access token in local storage and for axios
                     */
                    Container.get(GtrStorage).setItem(`${this.$data.event_identifier}_registration_access_token`, access_token)
                    this.$store.commit('auth/SET_VIEWABLE_REG_PAGES', response.data.viewable_reg_pages)
                    this.$store.commit('auth/SET_CURRENT_REG_PAGES', response.data.current_reg_pages)
                    if (Container.get(GtrStorage).getItem(`${this.$data.event_identifier}_group_uuid`)) {
                        const group_uuid = Container.get(GtrStorage).getItem(`${this.$data.event_identifier}_group_uuid`)
                        const data = {
                            event_identifier: this.$data.event_identifier,
                            group_uuid,
                        }
                        await this.$store.dispatch('register/addToGroupRegistration', data, { root: true })
                    }
                    if (payload.data.registrationTypeData) {
                        const registrationTypeData = payload.data.registrationTypeData

                        const dataOption = {
                            option_group_uuid: registrationTypeData.option_group_uuid,
                            option_uuid: registrationTypeData.option_uuid,
                            event_identifier: this.$data.event_identifier,
                            qty: 1, //default to 1 for now
                        }

                        await this.$store.dispatch('options/optionChange', dataOption, { root: true })
                    } else {
                        this.$router.push('/' + (this.isDev ? 'dev/' : '') + payload.event_identifier + '/leads/order/' + response.data.viewable_reg_pages.shift())
                    }
                } catch (error) {
                    Container.get(Notification).error(error.response.data.error_message)
                    /**
                     * If site is required, make the call to request for an invite here
                     */
                    if (error.response.data.error_code === 'INVITE_REQUIRED') {
                        this.$store.commit('auth/SET_REQUIRE_INVITE', true)
                    }
                }
            }
        } finally {
            this.$store.dispatch('common/hideLoader')
        }
    }

    async sendLoginDetails () {
        try {
            const payload: any = {
                event_identifier: this.$data.event_identifier,
                data: {}
            }
            for (const field in this.$data.loginFieldData) {
                payload.data[field] = this.$data.loginFieldData[field]
            }
            await this.$store.dispatch('auth/emailLoginDetails', payload)
        } catch (error) {
            Container.get(ErrorHandlerService).error(error)
        }
    }

    async sendResetPassword () {
        try {
            const payload: any = {
                event_identifier: this.$data.event_identifier,
                data: {}
            }
            for (const field in this.$data.loginFieldData) {
                payload.data[field] = this.$data.loginFieldData[field]
            }
            await this.$store.dispatch('auth/emailResetPassword', payload)
        } catch (error) {
            Container.get(ErrorHandlerService).error(error)
        }
    }

	getValueOfProperty(prop: string) {
		let value = ''
		if (Object.keys(this.design).length > 0){
			Object.keys(this.design).forEach((key: any) => {
                const parts = key.split('_')
				if(parts.shift() === this.template_name) {
					const property = parts.join('_')
					if(property === prop) {
						value = this.design[key]
					}
				}
			})
		}
		return value
	}
    //#endregion
}
